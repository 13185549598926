import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function ReviewApplication() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [application, setApplication] = useState(null);

  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((currentUser) => {
        if (currentUser) {
          const userRef = firebase.database().ref(`users/${currentUser.uid}`);
          userRef.on("value", (snapshot) => {
            const userData = snapshot.val();
            if (userData) {
              setUser({
                id: currentUser.uid,
                ...userData,
              });
            } else {
              setUser(null);
            }
            setLoading(false);
          });

          const applicationRef = firebase
            .firestore()
            .collection("applications")
            .where("userId", "==", currentUser.uid);
          applicationRef.onSnapshot((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              setApplication(doc.data());
            });
          });
        } else {
          setUser(null);
          setLoading(false);
        }
      });

    return () => unregisterAuthObserver(); // Make sure to unregister observer when unmounting
  }, []);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (!user) {
    return (
      <div>
        <Header />
        <div className="page-container">
          <h1>You need to sign in to view your application</h1>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div>
      <Header />
      <div className="page-container">
        {application ? (
          <div className="application-review">
            <button
              onClick={() => (window.location.href = "/home")}
              className="return-button"
            >
              {"<"} return
            </button>
            <h1>Review Your Application</h1>
            <div className="application-details">
              <h2>Company</h2>
              <p>Company Name: {application.question1}</p>
              <p>
                Describe the idea in 50 characters or less:{" "}
                {application.question2}
              </p>
              <p>URL/Link to your Pitch Deck: {application.question3}</p>
              <p>
                What category/industry best applies to your company?{" "}
                {application.question4}
              </p>
              <p>
                In detail, please explain what you're making and how the product
                works: {application.question5}
              </p>
              <p>
                Why did you pick this idea to work on? Do you a compelling
                insights in this area? How do you know people need what you're
                making? {application.question6}
              </p>
              <br />
              <h2>Team</h2>
              <p>
                Where are your currently based (must be in MENA):{" "}
                {application.question7}
              </p>
              <p>
                Co-Founder Profiles: Please list Name, Position, Age and
                University: {application.question8}
              </p>
              <p>
                How long have the founders known one another and how did you
                meet? Have any of the founders not met in person?{" "}
                {application.question9}
              </p>
              <p>
                How long have each of you been working on this? How much of that
                has been full-time? {application.question10}
              </p>
              <p>
                Who writes code, or does other technical work on your product?
                Was any of it done by a non-founder? Please explain:{" "}
                {application.question11}
              </p>
              <p>
                Have you built other things together as a team?{" "}
                {application.question12}
              </p>
              <p>Any rewards, competitions, awards? {application.question13}</p>
              <p>
                Have you gone through any other programs, accelerators or won
                awards? {application.question14}
              </p>
              <p>
                Are you committed to this idea or exploring others
                simultaneously? {application.question15}
              </p>
              <br />
              <h2>Progress</h2>
              <p>
                How far along are you? How much traction do you have?{" "}
                {application.question16}
              </p>
              <p>
                Do you have users/customers using your products?{" "}
                {application.question17}
              </p>
              <p>Do you have revenue?{" "}{application.question18}</p>
              <p>
                Are you currently raising? If so, how much? And what will you
                accomplish the round?{" "}{application.question19}
              </p>
            </div>
            <br />
            <h2>Market</h2>
            <p>
              Who are your competitors? What do you understand about your
              business that they don't?{" "}{application.question20}
            </p>
            <p>
              How big can this be? How many potential users exist? How would you
              get to at least $10M?{" "}{application.question21}
            </p>
          </div>
        ) : (
          <div>
            <h1>No application found</h1>
            <p>It looks like you haven't submitted an application yet.</p>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}
