//Pages
import SignUp from "./pages/SignUp";
import Home from "./pages/Home";
import Application from "./pages/Application";
import ReviewApplication from "./pages/ReviewApplication";
import "./App.css";

//Imports
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignUp />} />
        <Route index element={<SignUp />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/home" element={<Home />} />
        <Route path="/application" element={<Application />} />
        <Route path="/application/review-app" element={<ReviewApplication />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
