import Header from "../components/Header";
import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import firebaseConfig from "../components/firebaseConfig";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export default function Application() {
  const [formData, setFormData] = useState({
    // company
    question1: "",
    question2: "",
    question3: "",
    question4: "",
    question5: "",
    question6: "",

    // team
    question7: "",
    question8: "",
    question9: "",
    question10: "",
    question11: "",
    question12: "",
    question13: "",
    question14: "",
    question15: "",

    // progress
    question16: "",
    question17: "",
    question18: "",
    question19: "",

    // market
    question20: "",
    question21: "",
  });

  const handleSubmit = async () => {
    const db = firebase.firestore();
    const currentUser = firebase.auth().currentUser;

    if (currentUser) {
      const userId = currentUser.uid;

      if (window.confirm("Are you sure you want to submit the application?")) {
        try {
          await db.collection("applications").add({
            ...formData,
            userId: userId,
          });
          console.log("Application saved successfully!");
          window.location.href = "/home";
        } catch (error) {
          console.error("Error adding application: ", error);
        }
      }
    } else {
      console.error("No user logged in.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <Header />
      <div className="application-page">
        <button
          onClick={() => (window.location.href = "/home")}
          className="return-button"
        >
          {"<"} return
        </button>
        <h1>My Application:</h1>
        <p>Apply to pitch investors across the Gulf Region.</p>
        <h2>Company</h2>
        <p>Company Name</p>
        <input
          type="text"
          name="question1"
          value={formData.question1}
          onChange={handleChange}
        />
        <p>Describe the idea in 50 characters or less.</p>
        <textarea
          type="text"
          name="question2"
          value={formData.question2}
          onChange={handleChange}
        />
        <p>URL/Link to your Pitch Deck:</p>
        <input
          type="text"
          name="question3"
          value={formData.question3}
          onChange={handleChange}
        />
        <p>What category/industry best applies to your company?</p>
        <input
          type="text"
          name="question4"
          value={formData.question4}
          onChange={handleChange}
        />
        <p>
          In detail, please explain what you're making and how the product
          works.
        </p>
        <textarea
          type="text"
          name="question5"
          value={formData.question5}
          onChange={handleChange}
        />
        <p>
          Why did you pick this idea to work on? Do you a compelling insights in
          this area? How do you know people need what you're making?
        </p>
        <textarea
          type="text"
          name="question6"
          value={formData.question6}
          onChange={handleChange}
        />
        <h2>Team</h2>
        <p>Where are your currently based (must be in MENA)</p>
        <input
          type="text"
          name="question7"
          value={formData.question7}
          onChange={handleChange}
        />
        <p>
          Co-Founder Profiles: Please list Name, Position, Age and University
        </p>
        <textarea
          type="text"
          name="question8"
          value={formData.question8}
          onChange={handleChange}
        />
        <p>
          How long have the founders known one another and how did you meet?
          Have any of the founders not met in person?
        </p>
        <textarea
          type="text"
          name="question9"
          value={formData.question9}
          onChange={handleChange}
        />
        <p>
          How long have each of you been working on this? How much of that has
          been full-time?
        </p>
        <textarea
          type="text"
          name="question10"
          value={formData.question10}
          onChange={handleChange}
        />
        <p>
          Who writes code, or does other technical work on your product? Was any
          of it done by a non-founder? Please explain.
        </p>
        <textarea
          type="text"
          name="question11"
          value={formData.question11}
          onChange={handleChange}
        />
        <p>Have you built other things together as a team?</p>
        <textarea
          type="text"
          name="question12"
          value={formData.question12}
          onChange={handleChange}
        />
        <p>Any rewards, competitions, awards?</p>
        <textarea
          type="text"
          name="question13"
          value={formData.question13}
          onChange={handleChange}
        />
        <p>
          Have you gone through any other programs, accelerators or won awards?
        </p>
        <textarea
          type="text"
          name="question14"
          value={formData.question14}
          onChange={handleChange}
        />
        <p>
          Are you committed to this idea or exploring others simultaneously?
        </p>
        <textarea
          type="text"
          name="question15"
          value={formData.question15}
          onChange={handleChange}
        />
        <h2>Progress</h2>
        <p>How far along are you? How much traction do you have?</p>
        <textarea
          type="text"
          name="question16"
          value={formData.question16}
          onChange={handleChange}
        />
        <p>Do you have users/customers using your products?</p>
        <textarea
          type="text"
          name="question17"
          value={formData.question17}
          onChange={handleChange}
        />
        <p>Do you have revenue?</p>
        <textarea
          type="text"
          name="question18"
          value={formData.question18}
          onChange={handleChange}
        />
        <p>
          Are you currently raising? If so, how much? And what will you
          accomplish the round?
        </p>
        <textarea
          type="text"
          name="question19"
          value={formData.question19}
          onChange={handleChange}
        />
        <h2>Market</h2>
        <p>
          Who are your competitors? What do you understand about your business
          that they don't?
        </p>
        <textarea
          type="text"
          name="question20"
          value={formData.question20}
          onChange={handleChange}
        />
        <p>
          How big can this be? How many potential users exist? How would you get
          to at least $10M?
        </p>
        <textarea
          type="text"
          name="question21"
          value={formData.question21}
          onChange={handleChange}
        />
        <button
          className="save-button"
          style={{ marginTop: "5%" }}
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </>
  );
}
