import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/auth"; // Import firebase auth
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Profile() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [application, setApplication] = useState(null);

  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser) {
        const userRef = firebase.database().ref(`users/${currentUser.uid}`);
        userRef.on("value", (snapshot) => {
          const userData = snapshot.val();
          if (userData) {
            setUser({
              id: currentUser.uid,
              ...userData,
            });
          } else {
            setUser(null);
          }
          setLoading(false);
        });

        const applicationRef = firebase.firestore().collection("applications").where("userId", "==", currentUser.uid);
        applicationRef.onSnapshot((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            setApplication(doc.data());
          });
        });
      } else {
        setUser(null);
        setLoading(false);
        window.location.href = "/"; // Redirect to home if not signed in
      }
    });

    return () => unregisterAuthObserver(); // Unregister observer when unmounting
  }, []);

  const signOut = async () => {
    try {
      await firebase.auth().signOut();
      console.log("Signed out successfully");
      window.location.href = "/";
    } catch (error) {
      console.error("Error signing out:", error.message);
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  const startApplication = () => {
    window.location.href = "/application";
  };

  return (
    <div>
      <Header />
      <div className="page-container">
        <div className="top-half">
          <div className="application-status">
            {user && !application ? (
              <>
                <h1>Start your application</h1>
                <p>
                  <b>Note:</b>{" "}
                  <span className="subtext">
                    Fill out your startup details for investors to see.
                  </span>
                </p>
                <button className="apply-button" onClick={startApplication}>
                  Apply & get seen
                </button>
              </>
            ) : (
              <div className="user-application">
                <h1>Application Submitted</h1>
                <p>
                  Application for {application.question1} has been submitted.
                  You will hear back from investors typically within 3 weeks.
                </p>
                <p>
                  Review your submitted <a href={'/application/review-app'}>application</a>.
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="FAQ">
          <h1>Frequently Asked Questions</h1>
          <ul>
            <li>
              <p>
                <b>When will I hear back?</b>{" "}
                <span className="subtext">
                  You can expect a response within an average of three weeks.
                  Typically, you will hear from investors who are interested in
                  your project. If there is no interest, you may receive general
                  feedback instead.
                </span>
              </p>
            </li>
            <br />
            <br />
            <li>
              <p>
                <b>Is a decision guaranteed?</b>{" "}
                <span className="subtext">
                  Yes, you are guaranteed to receive a decision. However, this
                  does not guarantee that the decision will be a “yes.” You will
                  either gain interest from investors or they will decide to
                  pass on your startup
                  {"("}indicating no interest{")"}.
                </span>
              </p>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
}
